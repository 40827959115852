.footer {
    position: fixed;
    bottom: 2vw;
    width: 90vw;
    margin: 0 5vw;
    left: 0;
    /* padding-right: 10vw; */
    display: flex;
    justify-content: space-between;

}

.coffee {
    visibility: hidden;
}